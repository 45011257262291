import toDecimalPlaces from './chartData/toDecimalPlaces'
import { Units } from '~/constants/general'

export const numberFormat = (value: string | number, decimalPlaces: number = 0) => {
  if (typeof value !== 'number') return value

  return value.toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
    useGrouping: true,
  })
}

export const priceFormatter = (price: number, unit: string = '', decimalPlaces?: number) => {
  const valueWithDecimals = toDecimalPlaces(price, unit || Units.DOLLAR, true, true, decimalPlaces)
  const newValue = numberWithCommas(valueWithDecimals)

  return `${unit}${newValue}`
}

export const absNumberFormatter = (value: number, decimalPlaces?: number) => {
  return numberFormat(Math.abs(value), decimalPlaces)
}
